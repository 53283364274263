import { z } from 'zod'

export function isEmailValid(email: string) {
    const emailSchema = z.string().email()
    try {
        emailSchema.parse(email)
        return true
    } catch (error) {
        return false
    }
}

export const sendOtpSchema = z.object({
    email: z
        .string()
        .superRefine((value, ctx) => {
            if (value && !isEmailValid(value)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Incorrect email id',
                })
            }
        })
        .transform((value) => value.toLowerCase()),
})

export const verifyOtpSchema = z.object({
    otp: z.string().nonempty('OTP is required').min(6, ''),
})
